import React, { Component } from "react";
import Countdown from "react-countdown-now";
import ReactPlayer from 'react-player';
import { Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader } from "reactstrap";
import csImg from "../../assets/img/pages/rocket.png";
import "../../assets/scss/pages/coming-soon.scss";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";
// import { Document, Page } from 'react-pdf';
import Documetmodal from "./Documetmodal"
import Videomodel from "./Videomodel";
export default class Modalview extends Component {
  state = {
    spinner: true,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.openmodal !== this.props.openmodal) {
      this.setState({
        spinner: true
      });
    }
  }

  getmodalbody = () => {
    // console.log(this.props);
    switch (this.props.modaltype) {
      case 'pdf':
        return (

          // <div>
          //   <Document
          //     file={this.props.modalsrc}
          //     onLoadSuccess={this.onDocumentLoadSuccess}
          //   >
          //     <Page pageNumber={pageNumber} />
          //   </Document>
          //   <p>Page {pageNumber} of {numPages}</p>
          // </div>
          <iframe title={"title"} onLoad={() => {
            this.setState({ spinner: false })
          }}
            id="vd-vault-1" allow="autoplay; encrypted-media" scrollig="0" width="100%" height="100%" src={this.props.modalsrc}></iframe>

        );
      case 'ivideo':
        return (<iframe title={"title"} onLoad={() => {
          this.setState({ spinner: false })
        }}
          id="vd-vault-1" allow="autoplay; encrypted-media" scrollig="0" width="100%" height="100%" src={this.props.modalsrc}></iframe>
        );
      case 'video':
        console.log("playing video1")
        return (<ReactPlayer className='react-player' style={{ margin: "auto" }} onReady={() => {
          this.setState({ spinner: false })

        }} playing controls={true}
          height='100%'
          width="100%"
          url={this.props.modalsrc} />

        );
      case 'csoon':
        console.log("In coming soon");
        return (
          <Card className="mb-0">
            <CardHeader className="justify-content-center">
              <h2>We are launching soon</h2>
            </CardHeader>
            <CardBody className="text-center">
              <img src={csImg} alt="csImg" className="img-fluid width-150" />
              <div className="text-center getting-started pt-2 d-flex justify-content-center flex-wrap">
                <Countdown
                  date={Date.now() + parseInt(this.props.modalsrc)}
                  renderer={this.renderTimer}
                />
              </div>
            </CardBody>
          </Card>
        )
      case 'docs':
        console.log("showing docs");
        console.log(this.props.modaldata);
        return (
          <Documetmodal data={this.props.modaldata} onloadcall={() => {
            this.setState({
              spinner: false
            })
          }} />
        )
      case 'videolist':

        return (
          <Videomodel data={this.props.modaldata} onloadcall={() => {
            this.setState({
              spinner: false
            })
          }} />
        )
      default:
        return null;
    }
  }
  render() {
    return (
      <Modal
        isOpen={this.props.openmodal}
        toggle={this.props.toggleModal}
        className={`modal-dialog-centered modal-lg  ${this.props.modaltype === "docs" || this.props.modaltype === "videolist" ? "modaltest10" : "modaltest1"}`}
        contentClassName={this.props.modalclass ? this.props.modalclass : "modaltest4"}

      >
        <ModalHeader toggle={this.props.toggleModal}>
          {this.props.modalheader}
        </ModalHeader>
        <ModalBody style={{ overflow: "hidden" }}>
          {this.state.spinner ?
            < Spinner /> : null}
          {this.getmodalbody()}
        </ModalBody>
      </Modal>
    );
  }
}