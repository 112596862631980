import mock from "../mock"

// Contact
let data = {
  contacts: [
    {
      uid: 1,
      displayName: "Swiggy",
      photoURL: require("../../views/pages/swiggylogo.png"),
      status: "offline"
    },
    {
      uid: 2,
      displayName: "NYUC",
      photoURL: "https://i.ibb.co/s10pqxp/Whats-App-Image-2020-06-02-at-4-20-09-AM.jpg",
      status: "offline"
    }
  ],
  chats: {
    1: {
      isPinned: true,
      msg: [
        {
          textContent: "How do I order food from Swiggy App?",
          time: "Mon May 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          textContent:
            "I will surely help you. Do you have the Swiggy app on your mobile device?",
          time: "Mon May 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          textContent: "Yes",
          time: "Mon May 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          textContent: "Great, please open and login with your profile!",
          time: "Mon May 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          textContent:
            "Alright, Next?",
          time: "Mon May 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          textContent: "Now select your location & add your address to look for nearby restaurants",
          time: "Mon May 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          textContent: "Okay give me a minute",
          time: "Mon May 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          textContent: "Sure",
          time: "Mon May 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          textContent: "yeah all done",
          time: "Mon May 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          textContent: "Now Select any restaurant and select a cuisine to place your order",
          time: "Mon May 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          textContent: "Thanks a lot 👍.",
          time: "Mon May 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        }
      ]
    },
  }
}

// GET : CHATS AND CONTACTS
mock.onGet("/api/app/chat/chats").reply(() => {
  return [200, data]
})

// GET : CHAT LIST
mock.onGet("/api/app/chat/chat-contacts").reply(request => {
  const chatContactsArray = data.contacts.filter(contact => {
    if (data.chats[contact.uid]) return data.chats[contact.uid]
    else return null
  })

  return [200, chatContactsArray]
})

// POST : SEND MESSAGE
mock.onPost("api/app/chat/send-message").reply(request => {
  let reqdata = JSON.parse(request.data)
  const { contactId, message, isPinned } = reqdata
  if (data.chats[contactId]) {
    data.chats[contactId].msg.push(message)
  } else {
    let newMsg = {
      [contactId]: {
        isPinned: isPinned,
        msg: [message]
      }
    }
    Object.assign(data.chats, newMsg)
  }
  return [200]
})
mock.onPost("/api/apps/chat/mark-all-seen/").reply(request => {
  let contactId = JSON.parse(request.data).contactId

  // Get chat data
  let marked = data.chats[contactId]

  marked !== undefined &&
    marked.msg.forEach(msg => {
      msg.isSeen = true
    })

  return [200]
})
// POST : TOGGLE PINNED
mock.onPost("/api/apps/chat/set-pinned/").reply(request => {
  let { contactId, value } = JSON.parse(request.data)
  data.chats[contactId].isPinned = data.chats[contactId].isPinned = value
  return [200]
})
