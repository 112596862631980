// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  // console.log = () => {};
  
}
const config = {
  apiKey: "AIzaSyAiwhz4iO2x4gqCa7yVZgsqhQiiFfVKkgM",
  authDomain: "dvconindia-framez.firebaseapp.com",
  databaseURL: "https://dvconindia-framez-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dvconindia-framez",
  storageBucket: "dvconindia-framez.appspot.com",
  messagingSenderId: "147521444317",
  appId: "1:147521444317:web:5ede6f81b54c6b8df0be34",
  measurementId: "G-VTWFNFZSPH"
};
firebase.initializeApp(config)
firebase.analytics();

