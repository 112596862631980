import mock from "../mock"
const data = [
  {
    name: "Essie",

    email: "essie@vaill.com",
    game1score: 3574,
    game2score: 3574
  }
]
mock.onGet("/api/aggrid/data").reply(200, {
  data
})
