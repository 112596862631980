export const changeMode = mode => {
  return dispatch => dispatch({ type: "CHANGE_MODE", mode })
}

export const collapseSidebar = value => {
  return dispatch => dispatch({ type: "COLLAPSE_SIDEBAR", value })
}
export const customizerState = value => {
  return dispatch => dispatch({ type: "CUSTOMIZER_STATE", value })
}
export const disableCustomizer = value => {
  return dispatch => dispatch({ type: "DISABLE_CUSTOMIZER", value })
}
export const pollcustomizerState = value => {
  return dispatch => dispatch({ type: "POLL_CUSTOMIZER_STATE", value })
}
export const offcustomizer = value => {
  return dispatch => dispatch({ type: "OFF_CUSTOMIZER", value })
}
export const changeNavbarColor = color => {
  return dispatch => dispatch({ type: "CHANGE_NAVBAR_COLOR", color })
}
export const togglehometour = value => {
  return dispatch => dispatch({ type: "HOME_TOUR", value })
}
export const togglehomevideo = value => {
  return dispatch => dispatch({ type: "HOME_VIDEO", value })
}
export const changeNavbarType = style => {
  return dispatch => dispatch({ type: "CHANGE_NAVBAR_TYPE", style })
}

export const changeFooterType = style => {
  return dispatch => dispatch({ type: "CHANGE_FOOTER_TYPE", style })
}

export const changeMenuColor = style => {
  return dispatch => dispatch({ type: "CHANGE_MENU_COLOR", style })
}

export const hideScrollToTop = value => {
  return dispatch => dispatch({ type: "HIDE_SCROLL_TO_TOP", value })
}
