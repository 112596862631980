import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
// import "firebase/firestore"
import "firebase/storage"

import { history } from "../../../history"
import { JsonWebTokenError } from "jsonwebtoken"
// import { config } from "../../../authServices/firebase/firebaseConfig"
// console.log("hello5");
// // Init firebase if not already initialized
// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }
console.log("In loginactions");
let firebaseAuth = firebase.auth()
var initialchat = false;





export const updatepublicdata = (data) => {
  return dispatch => {
    dispatch({ type: "UPDATE_FIREBASE_DATA", data: data })
  }
}
export const getpublicdataofuser = (user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      firebase.database().ref("pub_user/" + user.uid).once("value", snap => {
        console.log("in getpublicdataofuser");
        if (snap.exists()) {
          console.log("got initial public data");
          console.log(snap.val());
          var finaldata = { eventdata: null, publicdata: snap.val(), privatedata: null };
          resolve({ e: 0, type: 0 });
          // startfirebaselistner(user.uid, dispatch);'
          // if (snap.data().student) {
          //   console.log("Student found");
          //   dispatch({ type: "DISABLE_CUSTOMIZER", value: true })
          // }
          dispatch({ type: "FIREBASE_DATA", data: finaldata })
          dispatch({ type: "CHANGE_ROLE", userRole: "admin" });
          dispatch({
            type: "LOGIN_WITH_EMAIL",
            payload: {
              email: user.email,
              uid: user.uid,
              isSignedIn: true,
              loggedInWith: "firebase"
            }
          });
        } else {
          resolve({ e: 0, type: 1 });

          dispatch({
            type: "LOGIN_WITH_EMAIL",
            payload: {
              email: user.email,
              uid: user.uid,
              isSignedIn: true,
              loggedInWith: "firebase"
            }
          });
        }
      }).catch(err => {
        console.log(err);
        console.log(typeof (err));
        // console.log(JSON.stringify(err));
        var r = window.confirm("Your network is unstable. Please reload");
        if (r == true) {
          window.location.reload();
        } else {
          console.log("Cancel");
        }
      })
    })
  }
}
// const initAuth0 = new auth0.WebAuth(configAuth)


export const resetloginpassword = (email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      firebaseAuth.sendPasswordResetEmail(email).then(function () {
        // Email sent.
        resolve({ e: true });

      }).catch(function (error) {
        // An error happened.
        resolve({ e: false, d: error });
      });
    });
  }

}
export const submitLoginWithFireBase = (email, password, remember) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // let userEmail = null,
      //   loggedIn = false
      firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(result => {
          result.user.updateProfile({
            displayName: "Admin"
          })
          resolve({ e: false, d: "done" });
        })
        .catch(error => {
          resolve({ e: true, d: error });
          console.log(error)
        })
    });
  }

}

export const loginWithFB = () => {
  return dispatch => {
    let provider = new firebase.auth.FacebookAuthProvider()
    provider.setCustomParameters({
      display: "popup"
    })
    firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        let token = result.credential.accessToken,
          // The signed-in user info.
          user = result.user.email
        dispatch({
          type: "LOGIN_WITH_FB",
          payload: {
            user,
            uid: result.user.uid,
            token,
            loggedInWith: "firebase"
          }
        })
        // if (user) history.push("/")
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const loginWithTwitter = () => {
  return dispatch => {
    let provider = new firebase.auth.TwitterAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_TWITTER",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const loginWithGoogle = () => {
  return dispatch => {
    let provider = new firebase.auth.GoogleAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_GOOGLE",
          payload: {
            email: user,
            name: name,
            uid: result.user.uid,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        // history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const loginWithGithub = () => {
  return dispatch => {
    let provider = new firebase.auth.GithubAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function (result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.additionalUserInfo.username,
          photoUrl = result.user.photoURL

        dispatch({
          type: "LOGIN_WITH_GITHUB",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}



export const logoutWithFirebase = user => {
  return dispatch => {
    console.log("Signoutt");
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
      console.log("Signed Out");
      dispatch({ type: "CHANGE_ROLE", userRole: "" })

      dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
      history.push("/pages/login")
    }, function (error) {
      // An error happened.
    });

  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
