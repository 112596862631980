import classnames from "classnames";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Search } from "react-feather";
import { connect } from "react-redux";
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "../../assets/scss/pages/coming-soon.scss";
import "../../assets/scss/plugins/tables/_agGridStyleOverride.scss";
import Spinner from "../../components/@vuexy/spinner/Loading-spinner";
import { setGlobalModal } from '../../redux/actions/nyucaction';
const ActionsComponentvideo = props => {
  return (
    <div className="data-list-action">
      <Button.Ripple color="danger" onClick={() => {
        // props.this.setState({
        //   modal: true,
        //   modalsrc: props.row.url,
        //   modalheader: props.row.name,
        //   modaltype: props.row.type,

        // })
        console.log(props);
      }}>Play</Button.Ripple>
    </div>
  )
}
const CustomHeader = props => {
  return (
    <div className="d-flex flex-wrap justify-content-between">
      {/* <div className="add-new">
        <Button.Ripple color="primary">Add New</Button.Ripple>
      </div> */}
      <div className="position-relative has-icon-left mb-1">
        <Input value={props.value} onChange={e => props.handleFilter(e)} />
        <div className="form-control-position">
          <Search size="15" />
        </div>
      </div>
    </div>
  )
}
var agendadata = {
  "audi1": {
    "name": "Auditorium 1",
    "data": [
      {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      },
      {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2",
        time: "Time2"
      }, {
        title: "Titlea1",
        time: "Time1"
      },
      {
        title: "Titlea2last",
        time: "Time2"
      },
    ]
  },
  "audi2": {
    "name": "Auditorium 2",
    "data": [
      {
        title: "Titleb1",
        time: "Time1"
      },
      {
        title: "Titleb2",
        time: "Time2"
      }
    ]
  },
  "audi3": {
    "name": "Auditorium 3",
    "data": [
      {
        title: "Titlec1",
        time: "Time1"
      },
      {
        title: "Titlec2",
        time: "Time2"
      }
    ]
  }
}
class GlobalModal extends Component {
  state = {
    spinner: true,
    active: "audi1",
    agendadata: {},
    currentdata: [],
    filteredData: [],
    value: "",
    paginationPageSize: 20,
    currenPageSize: "",
    getPageSize: "",
    columns: [
      {
        name: "Title",
        selector: "title",
        sortable: true,
        minWidth: "200px",
        cell: row => (
          <div className="d-flex flex-xl-row flex-column align-items-xl-center align-items-start py-xl-0 py-1">
            <div className="user-info text-truncate ml-xl-50 ml-0">
              <span
                title={row.title}
                className="d-block text-bold-500 text-truncate mb-0">
                {row.title}
              </span>
              <small title={row.time}>{row.time}</small>
            </div>
          </div>
        )
      },
      {
        name: "Action",
        // selector: "revenue",
        right: true,
        cell: row => (
          <ActionsComponentvideo
            row={row}
            this={this}
            getData={this.props.getData}
            parsedFilter={this.props.parsedFilter}
            currentData={this.handleCurrentData}
            deleteRow={this.handleDelete}
          />
        )
      }
    ],
  };
  toggletab = tab => {
    if (this.state.active !== tab) {
      this.setState({ active: tab, currentdata: this.state.agendadata[tab].data, value: "" })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.globalmodalstate !== this.props.globalmodalstate) {
      if (nextProps.globalmodaltype === "agenda") {
        console.log("Fetch agenda");
        console.log(agendadata);
        if (nextProps.globalmodaldata.audiid) {
          this.setState({
            active: nextProps.globalmodaldata.audiid,
            currentdata: agendadata[nextProps.globalmodaldata.audiid].data,
            filteredData: [],
            value: "",
            agendadata: agendadata,
            spinner: false
          })
        } else {
          this.setState({
            agendadata: agendadata,
            filteredData: [],
            value: "",
            currentdata: agendadata["audi1"].data,
            spinner: false
          })
        }
      } else {
        this.setState({
          spinner: true
        });
      }

    }
  }
  componentDidMount() {
    console.log("In global modal");
  }
  handleFilter = e => {
    let value = e.target.value
    let data = this.state.currentdata
    let filteredData = this.state.filteredData
    this.setState({ value })

    if (value.length) {
      filteredData = data.filter(item => {
        return item.title.toLowerCase().includes(value.toLowerCase())
        // let startsWithCondition =
        //   item.title.toLowerCase().startsWith(value.toLowerCase())
        // let includesCondition =
        //   item.title.toLowerCase().includes(value.toLowerCase()) ||
        //   item.date.toLowerCase().includes(value.toLowerCase()) ||
        //   item.email.toLowerCase().includes(value.toLowerCase()) ||
        //   item.revenue.toLowerCase().includes(value.toLowerCase()) ||
        //   item.status.toLowerCase().includes(value.toLowerCase())

        // if (startsWithCondition) {
        //   return startsWithCondition
        // } else if (!startsWithCondition && includesCondition) {
        //   return includesCondition
        // } else return null
      })
      this.setState({ filteredData })
    }
  }
  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.setState({
      currenPageSize: this.gridApi.paginationGetCurrentPage() + 1,
      getPageSize: this.gridApi.paginationGetPageSize(),
      totalPages: this.gridApi.paginationGetTotalPages()
    })
  }


  getmodalbody = () => {
    // console.log(this.props);
    switch (this.props.globalmodaltype) {
      case 'agenda':
        var agendakeys = Object.keys(this.state.agendadata);
        return (

          <Nav tabs style={{ height: "90%" }}>
            {agendakeys.map((key, i) => {
              return (
                <NavItem key={i}>
                  <NavLink
                    className={classnames({
                      active: this.state.active === key
                    })}
                    onClick={() => {
                      this.toggletab(key)
                    }}
                  >
                    {this.state.agendadata[key].name}
                  </NavLink>
                </NavItem>
              )
            })}
            <TabContent style={{ marginTop: 10, width: "100%", height: "90%" }} activeTab={this.state.active}>
              {agendakeys.map((key, i) => {

                return (
                  <TabPane key={i} tabId={key} style={{ height: "100%" }}>

                    <Card style={{ height: "100%" }}>
                      {/* <CardHeader>
                        <CardTitle> {this.state.agendadata[key].name}</CardTitle>
                      </CardHeader> */}
                      <CardBody style={{ height: "100%", overflowY: "scroll" }}>
                        {/* <div className="ag-theme-material w-100 my-2 ag-grid-table" style={{ height: "100%" }}>
                          <AgGridReact
                            gridOptions={{}}
                            rowSelection="multiple"
                            defaultColDef={{
                              sortable: true,
                              editable: true,
                              resizable: true,
                              suppressMenu: true
                            }}
                            columnDefs={[
                              {
                                headerName: "First Name",
                                field: "title",
                                width: 175,
                                filter: true,
                                checkboxSelection: true,
                                headerCheckboxSelectionFilteredOnly: true,
                                headerCheckboxSelection: true
                              },
                              {
                                headerName: "Last Name",
                                field: "time",
                                filter: true,
                                width: 175
                              }]}
                            rowData={this.state.currentdata}
                            onGridReady={this.onGridReady}
                            colResizeDefault={"shift"}
                            animateRows={true}
                            floatingFilter={true}
                            pagination={false}
                          // paginationPageSize={this.state.paginationPageSize}
                          // pivotPanelShow="always"
                          />
                        </div> */}

                        <DataTable
                          // className="dataTable-custom"
                          data={this.state.value.length ? this.state.filteredData : this.state.currentdata}
                          columns={this.state.columns}
                          noHeader
                          responsive
                          overflowY={true}
                          // pagination
                          subHeader
                          // fixedHeader={true}
                          subHeaderComponent={
                            <CustomHeader value={this.state.value} handleFilter={this.handleFilter} />
                          }
                        />
                        {/* {this.state.agendadata[key].name} */}
                      </CardBody>
                    </Card>
                  </TabPane>
                )
              })}
            </TabContent>
          </Nav>
        );
      // <iframe title={"title"} onLoad={() => {
      //   this.setState({ spinner: false })
      // }}
      //   id="vd-vault-1" allow="autoplay; encrypted-media" scrollig="0" width="100%" height="100%" src={"https://docs.google.com/gview?url=https://ijrar.com/upload_issue/ijrar_issue_20542895.pdf&embedded=true"}></iframe>
      // )



      default:
        return null;
    }
  }
  render() {
    return (
      <Modal
        isOpen={this.props.globalmodalstate}
        toggle={() => {
          this.props.setGlobalModal({
            state: false,
            type: "",
            data: {}
          })
        }}
        className="modal-dialog-centered modal-xl modaltest1"
        contentClassName="modaltest2"

      >
        <ModalHeader toggle={() => {
          this.props.setGlobalModal({
            state: false,
            type: "",
            data: {}
          })
        }}>
          {this.props.globalmodaldata.header ? this.props.globalmodaldata.header : ""}
        </ModalHeader>
        <ModalBody style={{ height: "80%" }}>
          {this.state.spinner ?
            < Spinner /> : null}
          {this.getmodalbody()}
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    globalmodalstate: state.nyucReducers.globalmodalstate,
    globalmodaltype: state.nyucReducers.globalmodaltype,
    globalmodaldata: state.nyucReducers.globalmodaldata
  }
}

export default connect(mapStateToProps, {
  setGlobalModal
})(GlobalModal);
