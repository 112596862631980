import * as firebase from "firebase/app";
// import "firebase/auth"
// import "firebase/database"
import "firebase/firestore";
import _ from "lodash";

function getuserlist(list, callback) {
    let userRefs = list.map(id => {
        return firebase.firestore().collection('pub_user').doc(id).get();
    });
    Promise.all(userRefs)
        .then(docs => {
            var users = {};
            docs.forEach(doc => {
                users[doc.id] = doc.data();
            });
            callback({ error: 0, data: users });
        })
        .catch(error => callback({ error: 1, err: error }))
}
export  const setGlobalModal = value => {
    return dispatch => dispatch({ type: "SHOW_GLOBAL_MODAL", value })
  }
export const addComment = (id, text, uid) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            console.log("Add comment");
            var batch = firebase.firestore().batch();
            var nycRef = firebase.firestore().collection("feed").doc(id);
            batch.update(nycRef, { comment: firebase.firestore.FieldValue.increment(1) });
            var sfRef = firebase.firestore().collection("feed").doc(id).collection("comments").doc();
            batch.set(sfRef, { authorid: uid, text: text, time: firebase.firestore.FieldValue.serverTimestamp() });
            batch.commit().then(() => {
                console.log("comment complete")
                // console.log(currentstatus);
                resolve({ error: 0, cid: sfRef.id });
            }).catch(err => {
                console.log("there was an error updating like");
            })
        });
    }
}
export const togglelike = (id, currentstatus, uid) => {

    return dispatch => {
        return new Promise((resolve, reject) => {
            resolve("done");
            console.log("Toggle Like");
            console.log(id);
            var batch = firebase.firestore().batch();
            var nycRef = firebase.firestore().collection("feed").doc(id);
            if (currentstatus) {
                batch.update(nycRef, { like: firebase.firestore.FieldValue.increment(-1) });
            } else {
                batch.update(nycRef, { like: firebase.firestore.FieldValue.increment(1) });
            }

            // Update the population of 'SF'
            var sfRef = firebase.firestore().collection("users").doc(uid);
            if (currentstatus) {
                batch.update(sfRef, { "alike": firebase.firestore.FieldValue.arrayRemove(id) });
            } else {
                batch.update(sfRef, { "alike": firebase.firestore.FieldValue.arrayUnion(id) });
            }
            batch.commit().then(() => {
                console.log("Like status")
                console.log(currentstatus);

                // ...
            }).catch(err => {
                console.log("there was an error updating like");
            })
            // const ref = firebase.firestore().collection('feed').doc()
            // console.log(ref.id)
            // ref.set({ id: ref.id, time: firebase.firestore.FieldValue.serverTimestamp() })  // sets the contents of the doc using the id
            //     .then(() => {  // fetch the doc again and show its data
            //         console.log("Created");
            //     }).catch(err => {
            //         console.log(err);
            //     })
        });
    }
}

export const getcomments = (id) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            console.log("Getting comments");
            var comments = [];
            firebase.firestore().collection('feed').doc(id).collection("comments").orderBy("time").get().then(csnap => {
                const tempDoc = [];
                var authors = [];
                csnap.forEach((doc) => {
                    authors.push(doc.data().authorid);
                    tempDoc.push({ id: doc.id, ...doc.data() })
                })
                var uniqauthor = _.uniq(authors);
                getuserlist(uniqauthor, (data) => {
                    console.log(data);
                    console.log(tempDoc);
                    var users = data.data;
                    var finaldata = [];
                    tempDoc.forEach(td => {
                        var d = td;
                        d["authordata"] = users[d.authorid];
                        finaldata.push(d);
                    });
                    console.log(finaldata);
                    resolve({ error: 0, data: finaldata })

                })
                // resolve(tempDoc);
                // console.log(tempDoc)
            }).catch(err => {
                resolve({ error: 1, err: err })
            })
        });
    }
}

export const getfeed = (searchtext, type, lastsnap, limit) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            console.log("Getting activity feed");
            var ref = null;
            if (lastsnap) {
                if (type === "all") {
                    ref = firebase.firestore().collection('feed').orderBy("time").startAfter(lastsnap).limit(limit);
                } else {
                    ref = firebase.firestore().collection('feed').where("type", "==", type).orderBy("time").startAfter(lastsnap).limit(limit);
                }
            } else {
                if (type === "all") {
                    ref = firebase.firestore().collection('feed').orderBy("time").limit(limit);
                } else {
                    ref = firebase.firestore().collection('feed').where("type", "==", type).orderBy("time").limit(limit);
                }
            }
            ref.get().then((querySnapshot) => {
                const tempDoc = [];
                var authors = [];
                var lastVisible = null;
                if (querySnapshot.docs.length === limit) {
                    lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                }
                querySnapshot.forEach((doc) => {
                    authors.push(doc.data().authorid);
                    tempDoc.push({ id: doc.id, ...doc.data() })
                })
                var uniqauthor = _.uniq(authors);
                getuserlist(uniqauthor, (data) => {
                    console.log(data);
                    console.log(tempDoc);
                    var users = data.data;
                    var finaldata = [];
                    tempDoc.forEach(td => {
                        var d = td;
                        d["authordata"] = users[d.authorid];
                        finaldata.push(d);
                    });
                    console.log(finaldata);
                    resolve({ error: 0, data: finaldata, lastsnap: lastVisible })

                })

            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
}