import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Easy Navigation"
  },
  // {
  //   id: "welcome",
  //   title: "Welcome Note",
  //   modaltype: "video",
  //   modalsrc: "https://www.youtube.com/watch?v=zzmQAA4e0f4",
  //   type: "modal",
  //   icon: <Icon.Calendar size={16} />
  // },
  {
    id: "home",
    title: "Lobby",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/",
  },

  {
    id: "helpdesk",
    title: "Help Desk",
    type: "item",
    icon: <Icon.Search size={20} />,
    navLink: "/helpdesk",
  },
  {
    id: "lounge",
    title: "Lounge",
    type: "item",
    icon: <Icon.PhoneCall size={20} />,
    navLink: "/lounge",
  },



  {
    type: "groupHeader",
    groupTitle: "Sessions"
  },
  {
    id: "agenda",
    title: "Agenda",
    type: "item",
    icon: <Icon.List size={20} />,
    navLink: "/agenda"
  },

  {
    id: "Auditorium",
    title: "Conference",
    type: "item",
    icon: <Icon.Eye size={20} />,
    navLink: "/auditorium",
  },
  {
    id: "speakers",
    title: "Speakers' Profiles",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    navLink: "/speakers",
  },



  {
    type: "groupHeader",
    groupTitle: "Information"
  },
  {
    id: "resource",
    title: "Document Library",
    type: "modal",
    modaltype: "docs",
    modalsrc: "",
    data: [{
      name: "Sponsors",
      url: "./docs/Sponsors.pdf"
    },
    {
      name: "FICCI EOS - July 2020",
      url: "./docs/FICCI EOS - July 2020.pdf"
    }, {
      name: "MANUFACTURING SURVEY REPORT",
      url: "./docs/MANUFACTURING SURVEY REPORT.pdf"
    }, {
      name: "FICCI Dhruva Survey",
      url: "./docs/Final FICCI Dhruva Survey 6.7.2020.pdf"
    }, {
      name: "WOB_Final Report_3103_V12",
      url: "./docs/WOB_Final Report_3103_V12_Final_compressed.pdf"
    },
    {
      name: "CAPAM Compendium 2020",
      url: "./docs/CAPAM Compendium 2020 Final.pdf"
    },{
      name: "L&T PROFILE",
      url: "./docs/L&T PROFILE.pdf"
    }
    ],
    icon: <Icon.File size={20} />,
    // navLink: "/resource",
  },
  {
    id: "library",
    title: "Video Library",
    type: "modal",
    modaltype: "videolist",
    modalsrc: "",
    data: [{
      name: "Welcome Video",
      url: "https://player.vimeo.com/video/439756126?autoplay=1"
    },
    {
      name: "L&T",
      url: "https://player.vimeo.com/video/439723343?autoplay=1"
    }
    ],
    icon: <Icon.Video size={20} />,
    // navLink: "/resource",
  },
  {
    id: "faq",
    title: "Technical Support",
    type: "item",
    icon: <Icon.Info size={20} />,
    navLink: "/faq",
  },
  // {
  //   id: "attendes",
  //   title: "Attendees",
  //   type: "item",
  //   icon: <Icon.Circle size={10} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/data-list/thumb-view"
  // },

  // {
  //   id: "gallery",
  //   title: "Gallery",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/components/carousel",
  // },





  // {
  //   id: "slider",
  //   title: "Slider",
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   navLink: "/slider",
  // },
  // {
  //   id: "leaderboard",
  //   title: "Leaderboard",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   navLink: "/leaderboard",
  // },
  // ,
  // {
  //   id: "page2",
  //   title: "Page 2",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/page2",
  // }

]

export default navigationConfig
