import axios from "axios"
import * as firebase from "firebase/app"
import { initOnLoad } from "apexcharts";
import { add } from "lodash";
// import img from "../../../assets/img/user.png"

var initialchat = false;
function getchatuserlist(list, chats, callback) {
  console.time("tet1")
  let userRefs = list.map(id => {
    return firebase.database().ref("pub_user/" + id).once("value");
  });
  Promise.all(userRefs)
    .then(docs => {
      console.timeEnd("tet1");
      var users = [];
      
      docs.forEach(doc => {
        console.log({ id: doc.key, ...doc.val(), chat: chats[doc.key] })
        users.push({ id: doc.key, ...doc.val(), chat: chats[doc.key] });
      });
      callback({ error: 0, data: users });
    })
    .catch(error => { console.log(error); callback({ error: 1, err: error }) })
}
export const changecurrenchatuser = (user) => {
  return dispatch => {
    console.log("changing current chat user");
    console.log(user);
    dispatch({
      type: "CURRENT_CHAT_USER",
      user: user
    })
  }
}
export const getChats = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      console.log("Starting firebase listner");
      console.log(firebase.auth().currentUser.uid);
      var cuid = firebase.auth().currentUser.uid;
      var ref = firebase.database().ref("chatd/" + cuid);
      var initialLoadDone = false;
      ref.on("child_changed", (changed) => {
        console.log("Firebase realtime chatd change");
        console.log(changed.key);
        console.log(changed.val());
        dispatch({
          type: "UPDATE_CHAT",
          id: changed.key,
          data: changed.val(),
          // contacts: data.data,
          // chats: initialchats
        })
      })
      ref.on("child_added", (added) => {
        if (initialLoadDone) {
          console.log("Added new");
          console.log(added.val());
          firebase.database().ref("pub_user/" + added.key).once("value", snap => {
            console.log("Adding new chat user");
            console.log(snap.val());
            var toadddata = { id: added.key, ...snap.val(), chat: added.val() };
            console.log(toadddata);
            dispatch({
              type: "ADD_CHAT_CONTACTS",
              chats: toadddata,
              totalnonread: added.val().us
            })
          })
        }
      })
      ref.once("value", (snap) => {
        console.log("firebase realitme database");
        console.log(snap.val());
        initialLoadDone = true;
        if (snap.exists()) {

          var maindata = snap.val();
          var uids = Object.keys(snap.val());
          getchatuserlist(uids, maindata, (data) => {
            if (data.error === 0) {
              console.log("Getting user list for chat");
              console.log(data.data);
              console.log(maindata);
              // dispatch({
              //   type: "GET_CONTACTS",
              //   // contacts: data.data,
              //   chats: maindata
              // })
              var totalnonread = 0;
              data.data.forEach(element => {
                totalnonread = totalnonread + (element.chat.us ? element.chat.us : 0);
              });

              dispatch({
                type: "GET_CHAT_CONTACTS",
                chats: data.data,
                totalnonread: totalnonread
              })
              console.log({ contacts: data.data, chats: maindata });
              resolve();
            } else {
              console.log("There was an error");
            }

          })
        } else {
          console.log("Empty Chat");
          dispatch({
            type: "GET_CHAT_CONTACTS",
            chats: [],
            totalnonread: 0
          })
          resolve();
        }
      })
    })



    // firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("chatd").onSnapshot(snapshot => {
    //   var initialchatdata = [];
    //   var initialchats = {};
    //   snapshot.docChanges().forEach(function (change) {
    //     if (change.type === "added") {
    //       if (!initialchat) {
    //         initialchats[change.doc.id] = change.doc.data();
    //         initialchatdata.push(change.doc.id);
    //       }
    //       console.log("New city: ", change.doc.data());
    //     }
    //     if (change.type === "modified") {
    //       console.log("Modified city: ", change.doc.data());
    //       dispatch({
    //         type: "UPDATE_CHAT",
    //         id: change.doc.id,
    //         data: change.doc.data(),
    //         // contacts: data.data,
    //         // chats: initialchats
    //       })
    //     }
    //     if (change.type === "removed") {
    //       console.log("Removed city: ", change.doc.data());
    //     }
    //   });
    //   if (!initialchat) {
    //     initialchat = true;
    //     console.log(initialchatdata);
    //     getuserlist(initialchatdata, (data) => {
    //       if (data.error === 0) {
    //         console.log("Getting user list for chat");
    //         console.log(data.data);
    //         console.log(initialchats);
    //         dispatch({
    //           type: "GET_CONTACTS",
    //           // contacts: data.data,
    //           chats: initialchats
    //         })
    //         dispatch({
    //           type: "GET_CHAT_CONTACTS",
    //           chats: data.data
    //         })
    //         console.log({ contacts: data.data, chats: initialchats });
    //       } else {
    //         console.log("There was an error");
    //       }

    //     })
    //     console.log("Getting user ids");
    //   }
    // })



    console.log("Getting chats");
    // axios
    //   .get("api/app/chat/chats")
    //   .then(response => {
    //     console.log("in getting chats");
    //     console.log(response.data);
    //     dispatch({
    //       type: "GET_CONTACTS",
    //       contacts: response.data.contacts,
    //       chats: response.data.chats
    //     })
    //   })
    //   .catch(err => console.log(err))
  }
}

export const getContactChats = () => {
  return dispatch => {
    console.log("Getting contacts chats");
    // axios
    //   .get("api/app/chat/chat-contacts")
    //   .then(response => {
    //     console.log(response.data);
    //     dispatch({
    //       type: "GET_CHAT_CONTACTS",
    //       chats: response.data
    //     })
    //   })
    //   .catch(err => console.log(err))
  }
}

export const togglePinned = (id, value) => {
  return dispatch => {
    console.log("In toggle pinned");
    axios
      .post("/api/apps/chat/set-pinned/", {
        contactId: id,
        value
      })
      .then(response => {
        console.log(response);
        dispatch({
          type: "SET_PINNED",
          id,
          value
        })
      })
      .catch(err => console.log(err))
  }
}

export const sendMessage = (id, isPinned, text) => {
  if (text.length > 0) {
    return dispatch => {
      console.log("In send message");

      let newMsg = {
        textContent: text,
        isSent: true,
        isSeen: false,
        time: new Date().toString()
      }
      console.log(newMsg);
      axios
        .post("/api/app/chat/send-message", {
          contactId: id,
          message: newMsg,
          isPinned
        })
        .then(response => {
          dispatch({
            type: "SEND_MESSAGE",
            msg: newMsg,
            id,
            isPinned,
            text
          })
          dispatch(getChats())
        })
        .catch(err => console.log(err))
    }
  } else {
    return
  }
}

export const changeStatus = status => {
  return dispatch => {
    dispatch({
      type: "CHANGE_STATUS",
      status
    })
  }
}

export const searchContacts = query => {
  return dispatch => {
    dispatch({
      type: "SEARCH_CONTACTS",
      query
    })
  }
}

export const markSeenAllMessages = id => {
  console.log("In mark seen all message");
  return dispatch => {
    axios
      .post("/api/apps/chat/mark-all-seen/", {
        contactId: id
      })
      .then(response => {
        console.log(response.data);
        dispatch({
          type: "MARK_AS_SEEN",
          id
        })
      })
      .catch(err => console.log(err))
  }
}
