import mock from "./mock"
import "./navbar/navbarSearch"
import "./navbar/navbarBookmarkSearch"
// import "./auth/authentication"
import "./apps/dataView"
import "./apps/calendar"
import "./apps/chat"
import "./tables/aggrid"

mock.onAny().passThrough()
