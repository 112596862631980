import classnames from "classnames"
import React from "react"
import { connect } from "react-redux"
import { Navbar } from "reactstrap"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import img from "../../../assets/img/user.png"
import logo from "./logo.png"

const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
const navbarTypes = ["floating", "static", "sticky", "hidden"]
// const ThemeNavbar = props => {

class ThemeNavbar extends React.Component {

  componentDidMount() {
    console.log("In navbar");
    // console.log(this.props.mdata);
    // console.log(this.props.mdata.fixed.name);
  }
  render() {
    return (
      <React.Fragment>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light": this.props.navbarColor === "default" || !colorsArr.includes(this.props.navbarColor),
              "navbar-dark": colorsArr.includes(this.props.navbarColor),
              "bg-primary":
                this.props.navbarColor === "primary" && this.props.navbarType !== "static",
              "bg-danger":
                this.props.navbarColor === "danger" && this.props.navbarType !== "static",
              "bg-success":
                this.props.navbarColor === "success" && this.props.navbarType !== "static",
              "bg-info":
                this.props.navbarColor === "info" && this.props.navbarType !== "static",
              "bg-warning":
                this.props.navbarColor === "warning" && this.props.navbarType !== "static",
              "bg-dark":
                this.props.navbarColor === "dark" && this.props.navbarType !== "static",
              "d-none": this.props.navbarType === "hidden" && !this.props.horizontal,
              "floating-nav":
                (this.props.navbarType === "floating" && !this.props.horizontal) || (!navbarTypes.includes(this.props.navbarType) && !this.props.horizontal),
              "navbar-static-top":
                this.props.navbarType === "static" && !this.props.horizontal,
              "fixed-top": this.props.navbarType === "sticky" || this.props.horizontal,
              "scrolling": this.props.horizontal && this.props.scrolling

            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div className="bookmark-wrapper">
                  <NavbarBookmarks
                    sidebarVisibility={this.props.sidebarVisibility}
                    handleAppOverlay={this.props.handleAppOverlay}
                  />
                </div>
                {/* {this.props.horizontal ? ( */}
                <div className="logo d-flex align-items-center" style={{ marginLeft: 90 }}>
                  <img src={logo} />
                  <h2 style={{ fontSize: 21, marginLeft: 10, textAlign: "center" }} className="text-primary brand-text mb-0">Education Fair 2020</h2>
                </div>
                {/* ) : null} */}
                <NavbarUser
                  handleAppOverlay={this.props.handleAppOverlay}
                  changeCurrentLang={this.props.changeCurrentLang}
                  userName={this.props.mdata ? this.props.mdata.publicdata.name : "Waiting"}
                  userImg={this.props.mdata ? this.props.mdata.publicdata.pic ? this.props.mdata.publicdata.pic : img : img}
                />
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment >
    )
  }
}



const mapStateToProps = state => {
  return {
    currentUser: state.auth.login.userRole,
    mdata: state.auth.login.data
  }
}

export default connect(mapStateToProps)(ThemeNavbar)

