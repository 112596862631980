import { data } from "../../../views/apps/ecommerce/shop/shopData"

export const login = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "FIREBASE_DATA": {
      return { ...state, data: action.data }
    }
    case "UPDATE_FIREBASE_DATA": {
      console.log("In update firebase user data");
      return {
        ...state, data: {
          ...state.data,
          publicdata: {
            ...state.data.publicdata,
            ...action.data.publicdata
          },
          privatedata: {
            ...state.data.privatedata,
            ...action.data.privatedata
          }
        }
      }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    case "AUTOPLAY_SETTING": {
      return { ...state, autoplay: action.autoplay }
    }
    default: {
      return state
    }
  }
}
