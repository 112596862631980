import React from "react"
import * as Icon from "react-feather"
import { connect } from "react-redux"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, NavItem, NavLink } from "reactstrap"
import { history } from "../../../history"
import { logoutWithFirebase } from "../../../redux/actions/auth/loginActions"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}
const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" onClick={e => handleNavigation(e, "/pages/account-settings")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">My Profile</span>
      </DropdownItem>
      {/* <DropdownItem tag="a" href="#">
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => props.logoutWithFirebase()}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {

  state = {
    navbarSearch: false,
    suggestions: [],
    notification: [],
  }

  componentDidMount() {

    // setTimeout(() => {
    //   var noti = [];
    //   noti.push({
    //     id: "hysp3aMrZJO8ikTP3fWpFlqMho42",
    //     company: "NYUC",
    //     position: "Developer",
    //     name: "Honney Goyal",
    //     type: "chat",
    //     pic: "https://firebasestorage.googleapis.com/v0/b/virtual-event-e2893.appspot.com/o/undefined%2Fpic.jpg?alt=media&token=cca0e68f-6dd5-47cb-a21a-1ce6c8f893ae",
    //     chat: {
    //       lm: {
    //         mes: "hello",
    //         time: 1593696001975
    //       },
    //       us: 0
    //     }
    //   })
    //   this.setState({
    //     notification: noti
    //   })

    // }, 3000);
  }




  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <NavItem className="nav-search" onClick={() => {
          console.log("Toggle maximize");
          var doc = window.document;
          var docEl = doc.documentElement;

          var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
          var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

          if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            requestFullScreen.call(docEl);
          }
          else {
            cancelFullScreen.call(doc);
          }
        }}>
          <NavLink className="nav-link-search">
            <Icon.Maximize2 style={{ color: "#000" }} size={21} data-tour="search" />
          </NavLink>
        </NavItem>



        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600" style={{ marginTop: 5 }}>
                {this.props.userName}
              </span>
              {/* <span className="user-status">Available</span> */}
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>

      </ul>
    )
  }
}
export default connect(null, {
  logoutWithFirebase
})(NavbarUser)
